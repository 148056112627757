import * as editor from './editor';
import {filesize} from "filesize";
import Alpine from 'alpinejs'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import './main.css';
 
window.filesize = filesize;
window.Alpine = Alpine
 

window.alpineLightbox = function () {
  return {
      init() {
        this.src = this.pixel;
        var nodes = document.querySelectorAll('img.photo');
        this.images = [];
        var i = 0;
        nodes.forEach((img) => {
            this.images.push({
                node: img,
                phototype: img.getAttribute('data-phototype'),
                species: img.getAttribute('data-species'),
                src: img.getAttribute('data-fullsize'),
                photographer: img.getAttribute('data-photographer'),
                source: img.getAttribute('data-source'),
                locality: img.getAttribute('data-locality'),
                uploaduser: img.getAttribute('data-uploaduser'),
                uploaduserprofile: img.getAttribute('data-uploaduserprofile'),
                comment: img.getAttribute('data-comment')
            });
            if (img === this.$el) this.current = i;
            i++;
        });
        this.$watch('current', (val) => {
            if (val <= -1)
                this.update(this.images.length + val);
            else if(val > this.images.length-1)
                this.update(val - this.images.length);
        });
    },
    update(index) {
        if (!this.isOpen) return;
        this.current = index;
        this.showCurrentImage();
    },
    current: 0,
    images: [],
    src: '',
    alt: '',
    phototype: '',
    photographer: '',
    species: '',
    source: '',
    locality: '',
    uploaduser: '',
    uploaduserprofile: '',
    comment: '',
    isOpen: false,
    showInfo: false,
    // One transparent pixel, to prevent broken img src: 
    pixel: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    open($event) {
        this.isOpen = true;
        this.update(this.images.findIndex(i => { return i.node == $event.target;}));
    },
    showCurrentImage() {
        if (typeof this.images[this.current] === 'undefined') {
            //console.log("Could not find element ", this.current);
            return;
        }
        var img = this.images[this.current].node;

        this.src = img.getAttribute('data-fullsize');
        this.alt = img.getAttribute('alt');
        this.species = img.getAttribute('data-species');
        this.phototype = img.getAttribute('data-phototype');
        this.photographer = img.getAttribute('data-photographer');
        this.source = img.getAttribute('data-source');
        this.locality = img.getAttribute('data-locality');
        this.uploaduser = img.getAttribute('data-uploaduser');
        this.uploaduserprofile = img.getAttribute('data-uploaduserprofile');
        this.comment = img.getAttribute('data-comment');

        // Prevent the window from scrolling
        const html = document.documentElement;
        html.classList.add('h-screen', 'overflow-hidden', 'scroll-none');
    },
    close() {
        // Close lightbox
        this.isOpen = false;

        // Return lightbox img src to transparent pixel
        this.src = this.pixel;
        // Remove lightbox img alt attribute
        this.alt = '';
        this.species = '';
        this.phototype = '';
        this.photographer = '';
        this.source = '';
        this.locality = '';
        this.uploaduser = '';
        this.uploaduserprofile = '';
        this.comment = '';

        // Return window to its normal state
        const html = document.documentElement;
        html.classList.remove('h-screen', 'overflow-hidden', 'scroll-none');
    }
  }
};


window.setPerPage = function (perPage) {
    if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set('per_page', perPage);
        searchParams.set('page', 1);
        window.location.search = searchParams.toString();
    }
};

window.setCatalogueParam = function (name, value) {
    if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set(name, value);
        window.location.search = searchParams.toString();
    }
};


document.addEventListener("keyup", e => {
    if (e.key !== "?" || e.ctrlKey || e.metaKey) return;
    if (/^(?:input|textarea|select|button)$/i.test(e.target.tagName)) return;

    e.preventDefault();
    const search = document.getElementById("search");
    if (search) {
      search.select();
      search.focus();
      window.scrollTo(0,0);
    }
});

tippy('[data-tippy-content]', {
    arrow: true,
    allowHTML: true
});

tippy('li.litteratur', {
  content(reference) {
    const id = reference.getAttribute('data-template');
    const template = document.getElementById(id);
    return template.innerHTML;
  },
  allowHTML: true,
});

tippy('span.omrcom', {
  content(reference) {
    const id = reference.getAttribute('data-template');
    const template = document.getElementById(id);
    return template.innerHTML;
  },
  allowHTML: true,
});

tippy('dl.poeng', {
  content(reference) {
    const id = reference.getAttribute('data-template');
    const template = document.getElementById(id);
    return template.innerHTML;
  },
  allowHTML: true,
});

Alpine.start()
editor.render();
