// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[x-cloak] { display: none; }

.deleted {
    -webkit-text-decoration: line-trough;
    text-decoration: line-trough;
    color: #999;
}

.animate-ping	{
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;

    @keyframes ping {
        75%, 100% {
            transform: scale(2);
            opacity: 0;
        }
    }
}

.content nav ul { list-style: none; }

.catalogue .table.is-narrow td, .table.is-narrow th {
    padding: 2px 2px;
}

.usercontent a {
     color: blue;
     text-decoration: underline;
}

.usercontent ul li {
   list-style-type: disc;
   list-style-position: inside;
}

.usercontent ol li {
   list-style-type: decimal;
   list-style-position: inside;
}

.content .deleted {
  text-decoration: line-through;
}
`, "",{"version":3,"sources":["webpack://./src/main.css"],"names":[],"mappings":"AAAA,YAAY,aAAa,EAAE;;AAE3B;IACI,oCAAoC;IACpC,4BAA4B;IAC5B,WAAW;AACf;;AAEA;IACI,sDAAsD;;IAEtD;QACI;YACI,mBAAmB;YACnB,UAAU;QACd;IACJ;AACJ;;AAEA,kBAAkB,gBAAgB,EAAE;;AAEpC;IACI,gBAAgB;AACpB;;AAEA;KACK,WAAW;KACX,0BAA0B;AAC/B;;AAEA;GACG,qBAAqB;GACrB,2BAA2B;AAC9B;;AAEA;GACG,wBAAwB;GACxB,2BAA2B;AAC9B;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":["[x-cloak] { display: none; }\n\n.deleted {\n    -webkit-text-decoration: line-trough;\n    text-decoration: line-trough;\n    color: #999;\n}\n\n.animate-ping\t{\n    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;\n\n    @keyframes ping {\n        75%, 100% {\n            transform: scale(2);\n            opacity: 0;\n        }\n    }\n}\n\n.content nav ul { list-style: none; }\n\n.catalogue .table.is-narrow td, .table.is-narrow th {\n    padding: 2px 2px;\n}\n\n.usercontent a {\n     color: blue;\n     text-decoration: underline;\n}\n\n.usercontent ul li {\n   list-style-type: disc;\n   list-style-position: inside;\n}\n\n.usercontent ol li {\n   list-style-type: decimal;\n   list-style-position: inside;\n}\n\n.content .deleted {\n  text-decoration: line-through;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
